import React from "react"
import Link from "gatsby-link"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateMarkup from "react-truncate-markup"

import Card from "react-bootstrap/Card"

import "./article-preview.scss"

const ArticlePreview = ({ post, highlighted, ...otherProps }) => {
  return (
    <Link to={`/article/${post.slug}`} className="card" className="wrapper">
      {post.heroImage && (post.heroImage.sizes || post.heroImage.fluid) && (
        // <div className={`${highlighted ? "highlightedImg" : "filteredImg"}`}>
        <div>
          {/* {highlighted ? (
            <Img
              className="card-img-top"
              className="img highlighted"
              title={post.title}
              fluid={post.heroImage.fluid}
            />
          ) : (
            <Img
              className="card-img-top"
              className="img"
              title={post.title}
              sizes={post.heroImage.sizes}
            />
          )} */}
          <GatsbyImage
            image={post.heroImage.gatsbyImageData}
            className="card-img-top img highlighted"
            title={post.title}
          />
        </div>
      )}
      <Card.Body
        className="body"
        style={{ height: highlighted ? "164px" : "128px" }}
      >
        <Card.Title className="title">
          <TruncateMarkup lines={2}>
            <h5 className={`${highlighted ? "highlightedTitle" : ""}`}>
              {post.title}
            </h5>
          </TruncateMarkup>
        </Card.Title>
        <p className="subtitle">
          <TruncateMarkup lines={2}>
            <span className={`${highlighted ? "highlightedSubtitle" : ""}`}>
              {post.subtitle}
            </span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default ArticlePreview
